/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  AttSearchRequest,
  ResponseListRankListAttrDto,
  ExpSearchH5Request,
  ResponseListExpSearchDto,
  AllSearchRequest,
  ResponseAllSearchDto,
} from '../types';

const baseUrl = '';

class AttSearchControllerService {
  /**
   * @summary 景区搜索
   * @fullUrl /att/attSearch
   */
  public attAttSearchPost(
    body: AttSearchRequest,
  ): ResponseType<ResponseListRankListAttrDto> {
    const requestUrl = baseUrl + '/att/attSearch';
    const res = doRequest<ResponseListRankListAttrDto>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 玩乐搜索
   * @fullUrl /att/expSearch
   */
  public attExpSearchPost(
    body: ExpSearchH5Request,
  ): ResponseType<ResponseListExpSearchDto> {
    const requestUrl = baseUrl + '/att/expSearch';
    const res = doRequest<ResponseListExpSearchDto>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 全部结果搜索
   * @fullUrl /att/allSearch
   */
  public attAllSearchPost(
    body: AllSearchRequest,
  ): ResponseType<ResponseAllSearchDto> {
    const requestUrl = baseUrl + '/att/allSearch';
    const res = doRequest<ResponseAllSearchDto>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const attSearchController = new AttSearchControllerService();
