import { useStorage } from '@vueuse/core'
import dayjs from 'dayjs'
import { attSearchController } from '~/apis/back/services'
import type { ExpSearchDto, RankListAttrDto } from '~/apis/back/types'
import { E_RANK_TYPE } from '~/enums'

export function useSearchAttr() {
  const route = useRoute()
  const router = useRouter()
  const filterConditionStore = useFilterConditionsStore()
  const userInfoStore = useUserStore()

  const searchAttrState = useState('AZGO_SEARCH_ATTR_STATE', () => {
    return {
      searchInput: route.query.query as string || '',
      confirmInput: '',
      searchAttrList: {
        [E_RANK_TYPE.ATTRACTIONS]: [] as Array<RankListAttrDto & { highlightName: string }>,
        [E_RANK_TYPE.EXPERIENCE]: [] as Array<ExpSearchDto & { highlightName: string }>,
      },
      isSearching: false,
      confirmAttrList: {
        [E_RANK_TYPE.ATTRACTIONS]: [] as Array<RankListAttrDto & { highlightName?: string }>,
        [E_RANK_TYPE.EXPERIENCE]: [] as Array<ExpSearchDto & { highlightName?: string }>,
      },
      searchLoading: false,
    }
  })
  const { searchInput, searchAttrList, isSearching, confirmAttrList, confirmInput, searchLoading } = toRefs(searchAttrState.value)

  const history = useStorage<string[]>('AZGO_SEARCH_ATTRS_HISTORY', [])
  function updateHistory(keyword: string) {
    history.value = history.value.filter(historyItem => keyword !== historyItem)

    if (history.value.length >= 5) {
      history.value.pop()
    }
    history.value.unshift(keyword)
  }

  async function doSearch(keyWord: string) {
    searchLoading.value = true

    const searhResult = await attSearchController.attAttSearchPost({
      distCode: route.query.campaign_id as string || 'azgo',
      keyWord,
      cityId: filterConditionStore.filterConditions.selectedCity?.destinationId?.toString() || '',
      tagIdList: filterConditionStore.listTagList,
      currency: userInfoStore.userInfo.currency,
      searchDate: dayjs(filterConditionStore.filterConditions.date).format('YYYY-MM-DD'),
      ...filterConditionStore.handleListParams(),
    })
    searchLoading.value = false
    return searhResult.data.value?.data || []
  }

  async function doAllSearch(keyWord: string) {
    searchLoading.value = true

    const distCode = route.query.campaign_id as string || 'azgo'
    const currency = userInfoStore.userInfo.currency
    const travelDate = dayjs(filterConditionStore.filterConditions.date).format('YYYY-MM-DD')
    const selectedCity = filterConditionStore.filterConditions.selectedCity

    const searhResult = await attSearchController.attAllSearchPost({
      expSearchReq: {
        distCode,
        keyWord,
        currency,
        travelDate,
        cityId: selectedCity?.destinationId?.toString() || '',
        cityName: selectedCity?.destinationName || '',
      },
      attSearchReq: {
        distCode,
        keyWord,
        cityId: selectedCity?.destinationId?.toString() || '',
        tagIdList: filterConditionStore.listTagList,
        currency,
        searchDate: travelDate,
      },
    })
    searchLoading.value = false
    return searhResult.data.value?.data || {}
  }

  async function doExpSearch(keyWord: string, filter: IFilterParams) {
    searchLoading.value = true
    const selectedCity = filterConditionStore.filterConditions.selectedCity
    const searhResult = await attSearchController.attExpSearchPost({
      distCode: route.query.campaign_id as string || 'azgo',
      keyWord,
      cityId: selectedCity?.destinationId?.toString() || '',
      cityName: selectedCity?.destinationName || '',
      currency: userInfoStore.userInfo.currency,
      travelDate: dayjs(filterConditionStore.filterConditions.date).format('YYYY-MM-DD'),
      ...filter,
    })
    searchLoading.value = false
    return searhResult.data.value?.data || []
  }

  function clearSearch() {
    confirmAttrList.value = {
      [E_RANK_TYPE.ATTRACTIONS]: [],
      [E_RANK_TYPE.EXPERIENCE]: [],
    }
    confirmInput.value = ''
    searchInput.value = ''
    searchAttrList.value = {
      [E_RANK_TYPE.ATTRACTIONS]: [],
      [E_RANK_TYPE.EXPERIENCE]: [],
    }
    isSearching.value = false
    searchLoading.value = false
  }

  function watchSearchInput() {
    watch(
      searchInput,
      debounce(async (value) => {
        if (!value) {
          clearSearch()
          navigateToList({ replace: true })

          return
        }
        if (value.length >= 1) {
          isSearching.value = true
          const searhResult = await doAllSearch(value)

          function highlightSearchTerm(text: string, searchTerm: string): string {
            const regex = new RegExp(`(${searchTerm.split('').join('|')})`, 'gi')
            return text.replace(regex, match => `<span style="color:var(--tc-color-primary)">${match}</span>`)
          }

          searchAttrList.value = {
            [E_RANK_TYPE.ATTRACTIONS]: searhResult.rankAttList?.map((item) => {
              const highlightName = item.attrName ? highlightSearchTerm(item.attrName, value) : ''
              return { ...item, highlightName }
            }) || [],
            [E_RANK_TYPE.EXPERIENCE]: searhResult.expSearchList?.map((item) => {
              const highlightName = item.specialTopicName ? highlightSearchTerm(item.specialTopicName, value) : ''
              return { ...item, highlightName }
            }) || [],
          }
        }
      }, 300),
    )
  }

  async function onConfirmAttr(rankType?: E_RANK_TYPE, item?: RankListAttrDto | ExpSearchDto) {
    if (!item || !rankType) {
      confirmAttrList.value = searchAttrList.value
      confirmInput.value = searchInput.value
    }
    else {
      confirmAttrList.value = {
        [E_RANK_TYPE.ATTRACTIONS]: rankType === E_RANK_TYPE.ATTRACTIONS ? [{ ...item, highlightName: (item as RankListAttrDto).attrName || '' }] : [],
        [E_RANK_TYPE.EXPERIENCE]: rankType === E_RANK_TYPE.EXPERIENCE ? [{ ...item, highlightName: (item as ExpSearchDto).specialTopicName || '' }] : [],
      }
      confirmInput.value = rankType === E_RANK_TYPE.ATTRACTIONS ? (item as RankListAttrDto).attrName || '' : (item as ExpSearchDto).specialTopicName || ''
    }

    updateHistory(confirmInput.value)

    router.replace({
      query: {
        ...route.query,
        query: confirmInput.value,
        tab_key: 0,
      },
    })
  }

  const searchNum = computed(() => {
    return confirmAttrList.value[E_RANK_TYPE.ATTRACTIONS].length + confirmAttrList.value[E_RANK_TYPE.EXPERIENCE].length
  })

  return {
    searchInput,
    searchAttrList,
    isSearching,
    confirmAttrList,
    confirmInput,
    searchLoading,
    onConfirmAttr,
    history,
    updateHistory,
    clearSearch,
    watchSearchInput,
    doSearch,
    doAllSearch,
    doExpSearch,
    searchNum,
  }
}
